<template>
  <div>
    <apexchart
      width="100%"
      type="line"
      :options="options"
      :series="graphPoints"
    ></apexchart>
  </div>
</template>

<script>
// data points per 1 x unit
const RESOLUTION = 10
// max x units
const XMAX = 24
const YMAX = 100.0
// const FLEXSAMPLES = 100
// const CISAMPLES = 100

function weibull(x, c, b) {
  return 1 - Math.exp(-Math.pow(x / b, c))
}
function bound (num, min, max) {
  if (num > max) return max;
  if (num < min) return min;
  return num;
}

export default {
  name: "CoatingsChart",
  props: {
    mainCurve: {
      type: Object,
    },
    secondaryCurve: {
      type: Object,
    },
    upperCI: {
      type: Object,
    },
    lowerCI: {
      type: Object,
    },
    title: {
      type: String,
    },
    showFlex: {
      type: Boolean,
    },
    isResults: {
      type: Boolean,
    },
    hideToolbar: {
      type: Boolean,
    },
    showCI: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      
    }
  },
  computed: {
    graphPoints: function () {
      var main = []
      var secondary = []
      // var upCI = []
      // var lowCI = []
      var series = []

      for (var i = 0; i <= XMAX * RESOLUTION; i++) {
        var x = i/RESOLUTION
        main.push({
          x: x,
          y: weibull(x, this.mainCurve.expC, this.mainCurve.bSquared) * this.mainCurve.aSquared,
        })

        // secondary curve
        if (this.secondaryCurve) {
          secondary.push({
            x: x,
            y: weibull(x, this.secondaryCurve.expC, this.secondaryCurve.bSquared) * this.secondaryCurve.aSquared,
          })
        }

        // // upper confidence interval
        // if (this.upperCI)
        // {
        //   upCI.push({
        //     x: x,
        //     y: weibull(x, this.upperCI.expC, this.upperCI.bSquared) * this.upperCI.aSquared,
        //   })
        // }

        // //lower confidence interval
        // if (this.lowerCI)
        // {
        //   lowCI.push({
        //     x: x,
        //     y: weibull(x, this.lowerCI.expC, this.lowerCI.bSquared) * this.lowerCI.aSquared,
        //   })
        // }
      }

      // main curve always
      series.push({
        name: this.isResults ? this.$t('CoatingGraphPredictedLabel') : this.$t('CoatingGraphDesiredLabel'),
        type: 'line',
        data: main,
      })

      if (this.showFlex && this.mainCurve.flex)
      {
        var flex = this.genFlex(this.mainCurve.flex)
        series.push({
          name: this.$t('CoatingGraphFlexUpLabel'),
          type: 'line',
          data: flex.up,
        })
        series.push({
          name: this.$t('CoatingGraphFlexLowLabel'),
          type: 'line',
          data: flex.low,
        })
      }

      if (this.showCI && this.upperCI && this.lowerCI){
        var CI = this.genCI(this.lowerCI, this.upperCI)

        series.push({
          name: this.$t('CoatingGraphHighCILabel'),
          type: 'line',
          data: CI.up,
        })
        series.push({
          name: this.$t('CoatingGraphLowCILabel'),
          type: 'line',
          data: CI.low,
        })
      }
      
      if (this.secondaryCurve){
        series.push({
          name: this.$t('CoatingGraphDesiredLabel'),
          type: 'line',
          data: secondary,
        })
      }
      return series
    },
    options: function () {
      return {
        chart: {
          id: 'chart',
          toolbar: {
            show: !this.hideToolbar,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
          },
        },
        stroke: {
          dashArray: this.showCI || this.showFlex ? [0, 5, 5, 0] : [0, 0],
        },
        colors: this.isResults ? 
                  this.showCI ? ['var(--eastman-blue)', 'var(--eastman-blue)', 'var(--eastman-blue)', 'var(--eastman-azure)'] 
                  : ['var(--eastman-blue)', 'var(--eastman-azure)'] 
                : ['var(--eastman-azure)', 'var(--eastman-azure)','var(--eastman-azure)'],
        title: {
          text: this.title,
          align: 'center',
        },
        xaxis: {
          type: 'numeric',
          title: {
            text: this.$t('CoatingGraphXAxis'),
            style: {
              fontSize: '16px',
              fontWeight: 'bold',
              fontFamily: 'Roboto',
            },
          }
        },
        yaxis: {
          min: 0.0,
          max: YMAX,
          tickAmount: 10.0,
          decimalsInFloat: 2,
          title: {
            text: this.$t('CoatingGraphYAxis'),
            style: {
              fontSize: '16px',
              fontWeight: 'bold',
              fontFamily: 'Roboto',
            },
          }
        },
      }
    },
  },
  methods: {
    genFlex: function (flex) {
      var up = []
      var low = []
      var curr = 0;

      const sampleA_min = bound(this.mainCurve.aSquared * (1-flex/100), 0, 100)
      const sampleA_max = bound(this.mainCurve.aSquared * (1+flex/100), 0, 100)
      const sampleB_min = bound(this.mainCurve.bSquared * (1-flex/100), 0.1, 24)
      const sampleB_max = bound(this.mainCurve.bSquared * (1+flex/100), 0.1, 24)
      const sampleC_min = bound(this.mainCurve.expC * (1-flex/100), 0.1, 50)
      const sampleC_max = bound(this.mainCurve.expC * (1+flex/100), 0.1, 50)

      var sampleParams = []
      for (var i = 0; i < 8; i++)
      {
        sampleParams.push({
          a: i % 2 == 0 ? sampleA_min : sampleA_max,
          b: Math.floor(i / 2) % 2 == 0 ? sampleB_min : sampleB_max,
          c: Math.floor(i / 4) === 1 ? sampleC_min : sampleC_max,
        })
      }

      // for every x on our graph
      for(i = 0; i <= XMAX * RESOLUTION; i++)
      {
        var x = i/RESOLUTION
        up.push({
          x: x,
          y: weibull(x, this.mainCurve.expC, this.mainCurve.bSquared) * this.mainCurve.aSquared,
        })
        low.push({
          x: x,
          y: weibull(x, this.mainCurve.expC, this.mainCurve.bSquared) * this.mainCurve.aSquared,
        })
        // sample values for aSq, bSq, expC within the flex range,
        // and add the highest and lowest values for that x to the flex lines
        // the highest and lowest points on a weibull curve might not occur
        // with all the highest / all the lowest values of those parameters, and may indeed be a mix
        // this sampling attempts to capture that approximately
        for (var j = 0; j < sampleParams.length; j++)
        {
          // get random inputs within the range
          var sampleA = sampleParams[j].a
          var sampleB = sampleParams[j].b
          var sampleC = sampleParams[j].c
          // plot inputs as weibull curve
          var sampleY = weibull(x, sampleC, sampleB) * sampleA
          // add to the upper or lower flex if that spot hasn't been set or they are higher than max / lower than min
          if (up[curr].y == undefined || sampleY > up[curr].y)
          {
            up[curr].y = sampleY
          }
          if (low[curr].y == undefined || sampleY < low[curr].y)
          {
            low[curr].y = sampleY
          }
        }
        curr++
      }

      return { up: up, low: low }
    },
    genCI: function (ciLow, ciUpper) {
      var upCI = []
      var lowCI = []
      var curr = 0;

      const sampleA_min = bound(ciLow.aSquared, 0, 100)
      const sampleA_max = bound(ciUpper.aSquared, 0, 100)
      const sampleB_min = bound(ciLow.bSquared, 0.1, 24)
      const sampleB_max = bound(ciUpper.bSquared, 0.1, 24)
      const sampleC_min = bound(ciLow.expC, 0.1, 50)
      const sampleC_max = bound(ciUpper.expC, 0.1, 50)

      // determine a set of random parameters within the CI bounds to flesh out CI
      var sampleParams = []
      for (var i = 0; i < 8; i++)
      {
        sampleParams.push({
          a: i % 2 == 0 ? sampleA_min : sampleA_max,
          b: Math.floor(i / 2) % 2 == 0 ? sampleB_min : sampleB_max,
          c: Math.floor(i / 4) === 1 ? sampleC_min : sampleC_max,
        })
      }

      // for every x on our graph
      for(i = 0; i <= XMAX * RESOLUTION; i++)
      {
        var x = i/RESOLUTION
        upCI.push({
          x: x,
          y: weibull(x, this.mainCurve.expC, this.mainCurve.bSquared) * this.mainCurve.aSquared,
        })
        lowCI.push({
          x: x,
          y: weibull(x, this.mainCurve.expC, this.mainCurve.bSquared) * this.mainCurve.aSquared,
        })
        // sample values for aSq, bSq, expC within the CI range,
        // and add the highest and lowest values for that x to the flex lines
        // the highest and lowest points on a weibull curve might not occur
        // on the exact upper and lower CI parameters
        // this sampling attempts to capture that approximately
        for (var j = 0; j < sampleParams.length; j++)
        {
          // get random inputs determined earlier
          var sampleA = sampleParams[j].a
          var sampleB = sampleParams[j].b
          var sampleC = sampleParams[j].c
          // plot inputs as weibull curve
          var sampleY = weibull(x, sampleC, sampleB) * sampleA
          // add to the upper or lower flex if that spot hasn't been set or they are higher than max / lower than min
          if (upCI[curr].y == undefined || sampleY > upCI[curr].y)
          {
            upCI[curr].y = sampleY
          }
          if (lowCI[curr].y == undefined || sampleY < lowCI[curr].y)
          {
            lowCI[curr].y = sampleY
          }
        }
        curr++
      }

      return { up: upCI, low: lowCI }
    }
  },
}
</script>

<style>

</style>